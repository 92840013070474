import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import './InquiryModal.scss'

// import imgGeneral from 'images/modal_general.jpg'
// import imgAutomotive from 'images/modal_automotive.jpg'
// import imgHighered from 'images/modal_highered.jpg'

import { Store } from 'store'
import HigherEd from "./Forms/HigherEd";
import Automotive from "./Forms/Automotive"
import General from "./Forms/General"
import NonProfit from './Forms/NonProfit'

// const img = {
//     general: imgGeneral,
//     automotive: imgAutomotive,
//     highered: imgHighered
// };

const InquiryModal = () => {
    const {state} = React.useContext(Store);
    const [submitted, setSubmitted] = React.useState(false)
    const [submitting, setSubmitting] = React.useState(false)
    const [recaptchaValue, setRecaptchaValue] = React.useState(null)
    // const [errors, setErrors] = React.useState(null)

    const onRecaptchaChange = React.useCallback((_recaptchaValue) => {
        setRecaptchaValue(_recaptchaValue)
    }, [])

    const handleFormSubmit = React.useCallback(async e => {
        e.preventDefault()
        setSubmitting(true)

        let form = e.target
        const formData = new FormData(form)

        let body = {}
        formData.forEach((value, key) => {body[key] = value})

        const response = await fetch(form.action, {
            method: form.method,
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({inquiryType: state.modalOpen, ...body})
        })

        setSubmitting(false)

        window.dataLayer.push({
            'event': 'ga',
            'eventCategory': 'Conversion',
            'eventAction': 'User Converted',
            'eventLabel': 'Booked a demo',
            'eventValue': '1'
        });

        if (!response.ok) {
            alert('Oops something went wrong')
            console.error(response)
            return
        }

        setSubmitted(true)
    }, [])

    let submitButtonLabel = submitting ? 'Submitting request' : 'Submit'

    if (submitted) {
        submitButtonLabel = 'Request submitted'
    }

    return (
        <>
            <div className="modal-form">
                <div className="form-container">
                    <div className="form-container-inner">
                        {/* <div className="form-image">
                            {state.modalOpen &&
                                <img src={img[state.modalOpen]} alt="Thumbnail" />
                            }
                        </div> */}
                        <div className="form-content">
                            <p className="form-intro">
                                <strong>Thanks for your interest.</strong>
                                <br/>
                                Please fill out the following information and a Shift <br /> Marketing Representative will contact
                                you shortly.
                            </p>

                            <form
                                onSubmit={handleFormSubmit}
                                name="inquiry_form"
                                action={`https://${process.env.GATSBY_API_HOST}/highered-demo-request`}
                                method='post'
                            >
                                {/*{errors && <div className="formerror">{errors}</div>}*/}

                                {
                                    state.modalOpen === 'highered'
                                        ? <HigherEd />
                                        : state.modalOpen === 'automotive'
                                            ? <Automotive />
                                            : state.modalOpen === 'general'
                                                ? <General />
                                                : state.modalOpen === 'nonprofit'
                                                ? <NonProfit />
                                                : null
                                }

                                <div className="form-row">
                                    <div className="form-field js-ajax-form-field">
                                        <ReCAPTCHA
                                            sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                                            onChange={onRecaptchaChange}
                                        />
                                    </div>
                                </div>

                                <div className="form-row-submit">
                                    <button
                                        style={{ margin: '0 auto' }}
                                        className="sh-button-primary __large"
                                        type="submit"
                                        data-text-success="Sent"
                                        disabled={submitting || submitted || recaptchaValue === null}
                                    >
                                        {submitButtonLabel}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InquiryModal
