import React from 'react';
import * as Form from 'components/Form'

const General = () => {
    const [industry, setIndustry] = React.useState(null);

    return (
        <>
            <input type="hidden" name="industry" value={industry}/>
            <div className="form-row">
                <Form.Field>
                    <Form.Dropdown
                        onChange={(value) => setIndustry(value)}
                        defaultValue={industry}
                        placeholder="Select your industry"
                        options={{
                            'higher education': 'Higher Education',
                            'automotive': 'Automotive',
                            'real estate': 'Real Estate',
                            'non profit': 'Non-profit',
                            'other': 'Other'
                        }}
                    />
                </Form.Field>
            </div>

            <div className="form-row">
                <div className="form-field js-ajax-form-field">
                    {/* <label htmlFor="form-company">Company / Institution Name</label> */}
                    <input
                        defaultValue=""
                        type="text"
                        id="inquiry_form_company"
                        name="company"
                        required="required"
                        placeholder="Company / Institution name"
                    />
                </div>
            </div>

            <div className="form-row">
                <div className="form-col-half">
                    <div className="form-field js-ajax-form-field">
                        {/* <label htmlFor="form-first-name">First Name</label> */}
                        <input
                            defaultValue=""
                            type="text"
                            id="inquiry_form_firstName"
                            name="firstName"
                            required="required"
                            placeholder="First name"
                        />
                    </div>
                </div>

                <div className="form-col-half">
                    <div className="form-field js-ajax-form-field">
                        {/* <label htmlFor="form-last-name">Last Name</label> */}
                        <input
                            defaultValue=""
                            type="text"
                            id="inquiry_form_lastName"
                            name="lastName"
                            required="required"
                            placeholder="Last name"
                        />
                    </div>
                </div>
            </div>

            <div className="form-row">
                <div className="form-col-half">
                    <div className="form-field js-ajax-form-field">
                        {/* <label htmlFor="form-email">Email Address</label> */}
                        <input
                            defaultValue=""
                            type="email"
                            id="inquiry_form_emailAddress"
                            name="emailAddress"
                            required="required"
                            placeholder="Email address"
                        />
                    </div>
                </div>

                <div className="form-col-half">
                    <div className="form-field js-ajax-form-field">
                        {/* <label htmlFor="phone-number">Phone Number</label> */}
                        <input
                            defaultValue=""
                            type="tel"
                            id="inquiry_form_phoneNumber"
                            name="phoneNumber"
                            required="required"
                            placeholder="Phone number"
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default General;
