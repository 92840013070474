import React from 'react'
import MediaQuery from 'react-responsive'
import cn from 'classnames'

import { Store } from 'store'
import { setModalOpen } from "layouts/DefaultLayout"

import styles from './FooterCTA.module.scss'

function FooterCTA (props) {
    const {dispatch} = React.useContext(Store)

    return (
        <div className={styles.container}>
            <section className={cn(styles.footerCta, {
                [styles.automotive]: props.modalType === 'automotive',
                [styles.nonprofit]: props.modalType === 'nonprofit',
                [styles.highered]: props.modalType === 'highered',
            })}>
                <div className={styles.innerContainer}>
                    <MediaQuery minWidth={769}>
                        <div className={styles.ctaText}>
                            {props.hideCtaText ? '' : 'Thousands of successful campaigns don\'t lie.'}
                        </div>
                        <div className={styles.footerCtaTitle}>
                            Ready to start your first campaign with Shift Marketing?
                        </div>

                        <div className={styles.footerCtaSubtitle}>
                            <a onClick={(e) => {e.preventDefault(); setModalOpen(dispatch, props.modalType)}} className="sh-button-primary __large" href="#">Book a demo</a>
                        </div>
                    </MediaQuery>

                    <MediaQuery maxWidth={768}>
                        <div className={styles.footerCtaTitle}>
                            <span>5,000+ successful events don’t lie.</span>
                            Ready to start your first campaign with Shift Marketing?
                        </div>

                        <div className={styles.footerCtaSubtitle}>
                            <a
                                onClick={(e) => {e.preventDefault(); setModalOpen(dispatch, props.modalType)}}
                                href="#"
                                className={cn("sh-button-primary __large", styles.btn)}
                            >
                                Book a Demo Today!
                            </a>
                        </div>
                    </MediaQuery>
                </div>
            </section>
        </div>
    );
};

export default FooterCTA
