// @flow
import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import cn from 'classnames'

import styles from './styles.module.scss'

export default function Form() {
    const [submitted, setSubmitted] = React.useState(false)
    const [submitting, setSubmitting] = React.useState(false)
    const [recaptchaValue, setRecaptchaValue] = React.useState(null)
    // const [errors, setErrors] = React.useState(null)

    const onRecaptchaChange = React.useCallback((_recaptchaValue) => {
        setRecaptchaValue(_recaptchaValue)
    }, [])

    const handleFormSubmit = React.useCallback(async e => {
        e.preventDefault()

        setSubmitting(true)

        let form = e.target
        const formData = new FormData(form)

        let body = {}
        formData.forEach((value, key) => {body[key] = value})

        const response = await fetch(form.action, {
            method: form.method,
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })

        setSubmitting(false)
        if (!response.ok) {
            alert('Oops something went wrong')
            console.error(response)
            return
        }

        setSubmitted(true)
    }, [])

    let submitButtonLabel = submitting ? 'Sending Request…' : 'Generate video'

    if (submitted) {
        submitButtonLabel = 'Video Request Sent'
    }

    return (
        <form
            onSubmit={handleFormSubmit}
            name="request-personalised-video"
            action={`https://${process.env.GATSBY_API_HOST}/personalised-video`}
            method='POST'
        >
            <div className="form-row">
                <div className="form-col-half">
                    <div className="form-field">
                        <label htmlFor="firstName">First Name</label>
                        <input type="text" name="firstName"/>
                    </div>
                </div>

                <div className="form-col-half">
                    <div className="form-field">
                        <label htmlFor="lastName">Last Name</label>
                        <input type="text" name="lastName"/>
                    </div>
                </div>
            </div>

            <div className="form-row">
                <div className="form-col-half">
                    <div className="form-field">
                        <label htmlFor="emailAddress">Email Address</label>
                        <input type="email" name="emailAddress"/>
                    </div>
                </div>

                <div className="form-col-half">
                    <div className="form-field">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input type="text" name="phoneNumber"/>
                    </div>
                </div>
            </div>

            <div className="form-row">
                <div className="form-col-half">
                    <div className="form-field">
                        <label htmlFor="city">City</label>
                        <input type="text" name="city"/>
                    </div>
                </div>

                <div className="form-col-half">
                    <div className="form-field">
                        <label htmlFor="company">Company / Institution Name</label>
                        <input type="text" name="company"/>
                    </div>
                </div>
            </div>
            <div className="form-row-submit" style={{ float: 'left', margin: '2em 4.5em 0 0' }}>
                <button
                    className="sh-button-primary"
                    type="submit"
                    data-text-success="Sent"
                    disabled={submitting || submitted || recaptchaValue === null}
                >
                    {submitButtonLabel}
                </button>
            </div>

            <div className={cn('form-row', styles.ReCAPTCHA)} style={{ display: 'flex', justifyContent: 'center', marginTop: '0' }}>
                <ReCAPTCHA
                    sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                    onChange={onRecaptchaChange}
                />
            </div>

        </form>
    )
}
