import React from "react"
import cn from 'classnames'

import styles from './Footer.module.scss'

const MenuMobile = () => (
    <>
        <div className={styles.contactInfoContainer}>
            <p>
                <strong className={styles.mobileParagraph}>
                    Speak to a Shift Marketing Representative about our products directly by calling or emailing us at:
                </strong>
            </p>

            <div className={cn(styles.contactBlocks, styles.contactInfoMobile)}>
                <div className={styles.contactInfo}>
                    <span><strong>Corporate Office & Automotive</strong></span>

                    {/* <span>#8-1815 Ironstone Manor</span> */}
                    {/* <span>Pickering, ON L1W 3W9</span> */}
                    {/* <br /> */}
                    <span>1.855.227.1717</span>
                    <span>auto@shiftmarking.io</span>
                </div>

                <div className={styles.contactInfo}>
                    <span><strong>U.S. Office, Higher Ed & Non-profit</strong></span>
                    {/* <span>#503 - 650 Franklin St.</span> */}
                    {/* <span>Schenectady, NY 12305</span> */}

                    {/* <br /> */}
                    <span>518.557.0088</span>
                    <span>highered@shiftmarking.io</span>
                </div>

                <div className={styles.contactInfo}>
                    <span><strong>Real Estate Office</strong></span>
                    {/* <span>#503 - 650 Franklin St.</span> */}
                    {/* <span>Schenectady, NY 12305</span> */}

                    {/* <br /> */}
                    <span>719.235.5084</span>
                    <span>info@fromyourfriends.com</span>
                </div>
            </div>
        </div>
    </>
)

export default MenuMobile
