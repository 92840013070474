import React from "react"
import MediaQuery from 'react-responsive';

import Logo from 'components/Logo'
import Menu from './Menu'
import MenuMobile from './MenuMobile'

import styles from './Footer.module.scss'

import logoSmall from 'images/logo_small.svg'
import growth2018 from './images/growth2018.svg'
import growth2019 from './images/growth2019.svg'
import growth2020 from './images/growth2020.svg'
import iconLinkedin from 'images/footer_social_icons/linkedin.svg'

const Footer = ({
    logoText
}) => (
    <footer className={styles.container}>
        <div className={styles.innerContainer}>
            <div className={styles.footerTop}>
                <div className={styles.logo}>
                    <Logo />
                    <span>{logoText}</span>
                    <span className={styles.rewards}>
                        <div style={{ backgroundImage: `url(${growth2018})` }} />
                        <div style={{ backgroundImage: `url(${growth2019})` }} />
                        <div style={{ backgroundImage: `url(${growth2020})` }} />
                    </span>
                </div>

                <MediaQuery minWidth={769}>
                    <Menu />
                </MediaQuery>

                <MediaQuery maxWidth={768}>
                    <MenuMobile />
                </MediaQuery>
            </div>

            <hr />

            <div className={styles.footerBottom}>
                <span className={styles.copyright}>
                    <img className={styles.logo} src={logoSmall} alt="Small logo" />© {(new Date).getFullYear()} <span className={styles.markInc}><strong>Shift Marketing Studio</strong>&nbsp;Inc. All rights reserved.</span>
                </span>
                <span className={styles.connect}>Connect with us on <a rel="noopener noreferrer" href="https://www.linkedin.com/company/shift-marketing-studio-inc-/" target="_blank">Linkedin</a> <img src={iconLinkedin} alt="LinkedIn icon" /></span>
            </div>
        </div>
    </footer>
)

export default Footer
