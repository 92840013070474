import React from 'react';
import styles from "layouts/DefaultLayout.module.scss";
import Modal from "react-modal";
import {setModalOpen} from "../../layouts/DefaultLayout";
import { Store } from "store";

import Form from './Form'

import imgGeneral from 'images/modal_general.jpg'
import imgAutomotive from 'images/modal_automotive.jpg'
import imgHighered from 'images/modal_highered.jpg'

const img = {
    general: imgGeneral,
    automotive: imgAutomotive,
    highered: imgHighered
};

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#___gatsby');

function PersonalizedVideoModal(props) {
    const {state, dispatch} = React.useContext(Store);

    return (
        <Modal
            isOpen={state.modalOpen && state.modalOpen.indexOf('personalized_video_') !== -1}
            onRequestClose={() => setModalOpen(dispatch, false)}
            style={{overlay: {backgroundColor: 'rgba(0, 0, 0, 0.45)', zIndex: 9999}, content: modalStyles}}
        >
            <div className={styles.closeBtn}>
                <button onClick={() => setModalOpen(dispatch, false)} className={styles.closeBtn}>&times;</button>
            </div>

            <div className="modal-form">
                <div className="form-container">
                    <div className="form-container-inner">
                        <div className="form-image">
                            {state.modalOpen &&
                            <img src={img[state.modalOpen.replace('personalized_video_', '')]} alt="Thumbnail" />
                            }
                        </div>
                        <div className="form-content">
                            <p className="form-intro">
                                Please fill out the fields below and we will generate your personalized video and send it to you when it has completed rendering.
                            </p>

                            <Form />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

const modalStyles = {
    borderRadius: '4px',
    position: 'absolute',
    top: '2em',
    left: '50%',
    right: 'auto',
    bottom: '2.5em',
    border: '0px',
    background: 'transparent',
    overflow: 'auto',
    outline: 'none',
    padding: '0',
    transform: 'translateX(-50%)',
    maxHeight: '100vh',
    overflowY: 'auto'
}

export default PersonalizedVideoModal;
