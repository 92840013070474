import React from 'react'

export const Store = React.createContext();

const initialState = {
    modalOpen: false,
    generate_video_step: 0,
    generate_video_automotive_step: 0
}

function reducer(state, action) {
    switch (action.type) {
        case 'SET_GENERATE_VIDEO_STEP':
            return { ...state, generate_video_step: action.payload }
        case 'SET_GENERATE_VIDEO_AUTOMOTIVE_STEP':
            return { ...state, generate_video_automotive_step: action.payload }
        case 'SET_MODAL':
            return { ...state, modalOpen: action.payload }
        default:
            throw new Error(`Action of type "${action.type}" is not defined`)
    }
}

export function StoreProvider(props) {
    const [state, dispatch] = React.useReducer(reducer, initialState)
    const value = { state, dispatch }
    return <Store.Provider value={value}>{props.children}</Store.Provider>
}
