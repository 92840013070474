import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import './ProductSheetModal.scss'

// import imgGeneral from 'images/modal_general.jpg'
// import imgAutomotive from 'images/modal_automotive.jpg'
// import imgHighered from 'images/modal_highered.jpg'

import { Store } from 'store'
import HigherEd from './Forms/HigherEd'
import Automotive from './Forms/Automotive'
import General from './Forms/General'
import NonProfit from './Forms/NonProfit'
import AnnualGiving from './Forms/AnnualGiving'
import HigherEdGivingPages from './Forms/HigherEdGivingPages'
import NonProfitGivingPages from './Forms/NonProfitGivingPages'
import HigherEdPhonathon from './Forms/HigherEdPhonathon'
import NonProfitPhonathon from './Forms/NonProfitPhonathon'

function ModalBody (props) {
    switch (props.name) {
        case'product_sheet_highered':
            return <HigherEd />
        case 'product_sheet_automotive':
            return <Automotive />
        case 'non_profit_phonathon_product_sheet':
            return <NonProfitPhonathon />
        case 'higher_ed_phonathon_product_sheet':
            return <HigherEdPhonathon />
        case 'product_sheet_general':
            return <General />
        case 'product_sheet_non-profit':
            return <NonProfit />
        case 'higher_ed_product_sheet_giving_pages':
            return <HigherEdGivingPages />
        case 'non_profit_product_sheet_giving_pages':
            return <NonProfitGivingPages />
        default:
            return <AnnualGiving />
    }
}

const ProductSheetModal = props => {
    const { state } = React.useContext(Store)
    const [submitted, setSubmitted] = React.useState(false)
    const [submitting, setSubmitting] = React.useState(false)
    const [recaptchaValue, setRecaptchaValue] = React.useState(null)
    // const [errors, setErrors] = React.useState(null)

    const onRecaptchaChange = React.useCallback(_recaptchaValue => {
        setRecaptchaValue(_recaptchaValue)
    }, [])

    const handleFormSubmit = React.useCallback(async e => {
        e.preventDefault()
        setSubmitting(true)

        let form = e.target
        const formData = new FormData(form)

        let body = {}
        formData.forEach((value, key) => {
            body[key] = value
        })

        const response = await fetch(form.action, {
            method: form.method,
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                inquiryType: state.modalOpen.replace('product_sheet_', ''),
                ...body,
            }),
        })

        setSubmitting(false)
        if (!response.ok) {
            alert('Oops something went wrong')
            console.error(response)
            return
        }

        setSubmitted(true)

        let fileUri = '/Shift-HigherEd-ProductSheet-Phoneathon-8.5x11-PROOF.pdf'

        const industry = body['industry']

        if (industry.indexOf('higher_ed_phonathon_product_sheet') !== -1) {
            fileUri = '/Shift-HigherEd-ProductSheet-Phoneathon-8.5x11-PROOF.pdf'
        } else if (industry.indexOf('non_profit_phonathon_product_sheet') !== -1) {
            fileUri = '/NonProfit-ProductSheet-Phoneathon.pdf'
        } else if (industry.indexOf('non_profit_product_sheet_giving_pages') !== -1) {
            fileUri = '/Shift-NonProfit-GivingPages.pdf'
        } else if (industry.indexOf('higher_ed_product_sheet_giving_pages') !== -1) {
            fileUri = '/Shift-HigherEd-ProductSheet-GivingPages.pdf'
        } else if (industry.indexOf('automotive') !== -1) {
            fileUri = '/Shift-ProductSheets-Automotive.pdf'
        } else if (industry.indexOf('non-profit') !== -1) {
            fileUri = '/NonProfit-ProductSheet-Phoneathon.pdf'
        } else if (industry.indexOf('annual-giving') !== -1) {
            fileUri = '/Giving-Pages-Product-Sheet.pdf'
        }

        window.open(fileUri)
    }, [])

    let submitButtonLabel = submitting
        ? 'Requesting file…'
        : 'Download product sheets'

    if (submitted) {
        submitButtonLabel = 'Product Sheet downloaded'
    }

    return (
        <>
            <div className="modal-form">
                <div className="form-container">
                    <div className="form-container-inner">
                        <div className="form-content">
                            <p className="form-intro">
                                <strong>
                                    Thanks for your interest in our product
                                    sheets.
                                </strong>
                                <br />
                                Please fill out the following information to
                                download.
                            </p>

                            <form
                                onSubmit={handleFormSubmit}
                                name="product_sheet_form"
                                action={`https://${process.env.GATSBY_API_HOST}/product-sheet-download-notification`}
                                method="post"
                            >
                                <ModalBody name={state.modalOpen} />

                                <div className="form-row">
                                    <div className="form-field js-ajax-form-field">
                                        <ReCAPTCHA
                                            sitekey={
                                                process.env
                                                    .GATSBY_RECAPTCHA_SITE_KEY
                                            }
                                            onChange={onRecaptchaChange}
                                        />
                                    </div>
                                </div>

                                <div className="form-row-submit">
                                    <button
                                        style={{
                                            margin: '0 auto',
                                            minWidth: '250px',
                                        }}
                                        className="sh-button-primary __large"
                                        type="submit"
                                        data-text-success="Sent"
                                        disabled={
                                            submitting ||
                                            submitted ||
                                            recaptchaValue === null
                                        }
                                    >
                                        {submitButtonLabel}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductSheetModal
