import React from 'react';
import styles from "layouts/DefaultLayout.module.scss";
import Modal from "react-modal";
import {setModalOpen} from "../../layouts/DefaultLayout";
import { Store } from "store";
import ProductSheetModal from "../ProductSheetModal/ProductSheetModal";

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement('#___gatsby');

function DefaultLayoutProductSheetModal(props) {
    const {state, dispatch} = React.useContext(Store);

    return (
        <Modal
            isOpen={state.modalOpen && state.modalOpen.indexOf('product_sheet') !== -1}
            onRequestClose={() => setModalOpen(dispatch, false)}
            style={{overlay: {backgroundColor: 'rgba(127, 127, 127, 0.95)', zIndex: 9999}, content: modalStyles}}
        >
            <div className={styles.closeBtn}>
                <button onClick={() => setModalOpen(dispatch, false)} className={styles.closeBtn}>&times;</button>
            </div>
            <ProductSheetModal type={state.modalOpen}/>
        </Modal>
    );
}

const modalStyles = {
    borderRadius: '4px',
    position: 'absolute',
    top: '2em',
    left: '50%',
    right: 'auto',
    bottom: '2.5em',
    border: '0px',
    background: 'transparent',
    overflow: 'auto',
    outline: 'none',
    padding: '0',
    transform: 'translateX(-50%)',
    maxHeight: '100vh',
    overflowY: 'auto'
}

export default DefaultLayoutProductSheetModal;
