import { Link } from "gatsby"
import React from "react"
import cn from 'classnames'

import styles from './Footer.module.scss'

const Menu = () => (
    <div className={styles.menuContainer}>
        <div className={styles.contactInfoContainer}>
            <p><strong>Speak to a Shift Marketing Representative about our products directly by calling or emailing us
                at:</strong></p>

            <div className={styles.contactBlocks}>
                <div className={styles.contactInfo}>
                    <span className={styles.contactInfoTitle}><strong>Corporate Office & Automotive</strong></span>
                    {/* <span>#8 - 1815 Ironstone Manor</span> */}
                    {/* <span>Pickering, Ontario L1W 3W9</span> */}
                    {/* <br /> */}
                    <span><strong>Phone:</strong>	1.855.227.1717</span>
                    <span><strong>Email:</strong>	auto@shiftmarking.io</span>
                </div>

                <div className={styles.contactInfo}>
                    <span className={cn(styles.contactInfoTitle, styles.contactInfoTitleRight)}><strong>U.S. Office & Higher Education & Non-profit</strong></span>
                    {/* <span>#503 - 650 Franklin Street</span> */}
                    {/* <span>Schenectady, New York 12305</span> */}
                    {/* <br /> */}
                    <span><strong>Phone:</strong>	518.557.0088</span>
                    <span><strong>Email:</strong>	highered@shiftmarking.io</span>
                </div>

                <div className={styles.contactInfo}>
                    <span className={cn(styles.contactInfoTitle, styles.contactInfoTitleRight)}><strong>Real Estate Office</strong></span>
                    {/* <span>#503 - 650 Franklin Street</span> */}
                    {/* <span>Schenectady, New York 12305</span> */}
                    {/* <br /> */}
                    <span><strong>Phone:</strong>	719.235.5084</span>
                    <span><strong>Email:</strong>	info@fromyourfriends.com</span>
                </div>
            </div>
        </div>

        {/*<div className={styles.menus}>*/}
        {/*    <div className={styles.menu}>*/}
        {/*        <ul>*/}
        {/*            <li><Link to="/">Home</Link></li>*/}
        {/*            <li><Link to="/how-it-works">How It Works</Link></li>*/}
        {/*            <li><Link to="/contact">Contact Us</Link></li>*/}
        {/*            /!*<li><Link to="/privacy-policy">Privacy Policy</Link></li>*!/*/}
        {/*        </ul>*/}
        {/*    </div>*/}

        {/*    <div className={styles.menu}>*/}
        {/*        <ul>*/}
        {/*            <li className={styles.menuTitle}>Industries We Serve</li>*/}
        {/*            <li><Link to="/automotive">Automotive</Link></li>*/}
        {/*            <li><Link to="/highered">Higher Education</Link></li>*/}
        {/*            <li><Link to="/non-profit">Non-profit</Link></li>*/}
        {/*            <li><Link to="/real-estate">Real Estate</Link></li>*/}
        {/*            <li><a className={styles.disabled} href="#">Dental (coming soon)</a></li>*/}
        {/*        </ul>*/}
        {/*    </div>*/}
        {/*</div>*/}
    </div>
)

export default Menu
