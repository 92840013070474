import * as React from "react"

function IconLogin (props) {
  return (
    <svg width={props.width} height={props.height} viewBox={`0 0 ${props.width} ${props.height}`} {...props}>
      <g fill="#453F3F" fillRule="evenodd">
        <path d="M12.963 11.523c-.3 0-.543.237-.543.528 0 .291.244.528.543.528 2.712 0 4.918 2.145 4.918 4.782v.536H1.086v-.536c0-2.637 2.206-4.782 4.918-4.782.299 0 .542-.237.542-.528a.536.536 0 00-.542-.528c-3.31 0-6.004 2.62-6.004 5.838v1.064c0 .291.244.528.543.528h17.88c.3 0 .544-.237.544-.528v-1.064c0-3.219-2.694-5.838-6.004-5.838M5.19 5.231c0-2.302 1.926-4.175 4.293-4.175 2.368 0 4.294 1.873 4.294 4.175 0 2.302-1.926 4.175-4.294 4.175-2.367 0-4.293-1.873-4.293-4.175m4.293 5.231c2.967 0 5.38-2.347 5.38-5.231S12.45 0 9.483 0c-2.966 0-5.38 2.347-5.38 5.231s2.414 5.231 5.38 5.231" />
      </g>
    </svg>
  )
}

export default IconLogin
