import { Link } from 'gatsby'
import MediaQuery from 'react-responsive'
import React from "react"
import cn from 'classnames'
import { Store } from 'store'
import { setModalOpen } from "layouts/DefaultLayout"

import Logo from 'components/Logo'

import styles from './Header.module.scss'

import iconAuto from './images/icon_menu_automotive.svg'
import iconHigherEd from './images/icon_menu_highered.svg'
import dropdownArrow from './images/dropdown_arrow.svg'
import iconMenu from './images/icon_menu.svg'
import iconCloseMenu from './images/icon_close_menu.svg'
import iconNonprofit from './images/icon_non_profit.svg'
import iconRealEstate from './images/icon_real_estate.svg'
import iconDental from './images/icon_dental_logo.svg'
import IconLogin from './IconLogin'

const Header = (props) => {
    const {dispatch} = React.useContext(Store)
    const dropdownAnchorRef = React.useRef()
    const [mobileMenu, setMobileMenu] = React.useState(false)
    const [dropdown, setDropdown] = React.useState(false)

    const toggleDropdown = (e) => {
        e.preventDefault();

        if (window.innerWidth <= 992) {
            setDropdown(!dropdown);
        }
    };

    const handleEventListener = React.useCallback(e => {
        if (e.target !== dropdownAnchorRef.current) {
            setDropdown(false);
        }
    }, [dropdownAnchorRef])

    React.useEffect(() => {
        if (window.innerWidth <= 992) {
            document.addEventListener('click', handleEventListener);
        }
        return () => {
            document.removeEventListener('click', handleEventListener)
        }
    }, [handleEventListener]);

    return (
        <header className={styles.container}>
            <div className={styles.innerContainer}>
                <div>
                    <a className={styles.logo} href="/">
                    <MediaQuery maxWidth='768px'>
                        <Logo small={true} />
                    </MediaQuery>
                    <MediaQuery minWidth='767px'>
                        <Logo small={false} />
                        <span>{props.logoText}</span>
                    </MediaQuery>
                </a>
                </div>

                <button className={styles.menuButton} onClick={() => setMobileMenu(!mobileMenu)}>
                    {mobileMenu ?
                        <img src={iconCloseMenu} alt="iconCloseMenu" />
                        :
                        <img src={iconMenu} alt="Menu Icon" />
                    }
                </button>

                <div className={cn(styles.right, { [styles.mobileDisplay]: mobileMenu })}>
                    {/*<Link to="/how-it-works" className={styles.menuItem}>How It Works</Link>*/}

                    <div className={styles.dropdown}>
                        <a ref={dropdownAnchorRef} href="#" className={styles.menuItem} onClick={toggleDropdown}>Industries <ArrowDown /></a>

                        <div className={cn(styles.dropdownInner, {[styles.mobileDropdown]: dropdown})}>
                            <div className={styles.dropdownInnerBorder}>
                                <img className={styles.dropdownBubbleArrow} src={dropdownArrow} />

                                <div className={styles.dropdownInnerInner}>
                                    <ul>
                                        <li>
                                            <Link to="/automotive">
                                                <span className={styles.dropdownMenuIcon}>
                                                    <img src={iconAuto} alt="Auto" />
                                                </span>

                                                <span className={styles.dropdownMenuLabel}>
                                                    Automotive
                                                </span>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/highered">
                                                <span className={styles.dropdownMenuIcon}>
                                                    <img src={iconHigherEd} alt="Higher Ed" />
                                                </span>

                                                <span className={styles.dropdownMenuLabel}>
                                                    Higher Education
                                                </span>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/non-profit">
                                                <span className={styles.dropdownMenuIcon}>
                                                    <img src={iconNonprofit} alt="Non-profit" />
                                                </span>

                                                <span className={styles.dropdownMenuLabel}>
                                                    Non-profit
                                                </span>
                                            </Link>
                                        </li>

                                        <li>
                                            <Link to="/real-estate">
                                                <span className={styles.dropdownMenuIcon}>
                                                    <img src={iconRealEstate} alt="Real-estate" />
                                                </span>

                                                <span className={styles.dropdownMenuLabel}>
                                                    Real Estate
                                                </span>
                                            </Link>
                                        </li>

                                        {/*<li className={styles.disabled}>*/}
                                        {/*    <Link to="#" aria-disabled={true} tabindex="-1">*/}
                                        {/*        <span className={styles.dropdownMenuIcon}>*/}
                                        {/*            <img src={iconDental} alt="dental" />*/}
                                        {/*        </span>*/}

                                        {/*        <span className={styles.dropdownMenuLabel}>*/}
                                        {/*            Dental (coming soon)*/}
                                        {/*        </span>*/}
                                        {/*    </Link>*/}
                                        {/*</li>*/}
                                    </ul>

                                    <hr />

                                    <p><strong>Don't see your industry listed?</strong></p>
                                    <p><Link to="/contact">Contact us</Link> {/* about our custom campaigns.*/}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <MediaQuery minWidth={769}>
                        <button onClick={() => setModalOpen(dispatch, props.modalType)} className="sh-button-primary">Request a demo</button>
                    </MediaQuery>

                    <a target="_blank" href="https://app.shiftone.io/" className={styles.menuItem}>
                        <span className={styles.menuItemLabel}>Log-In</span>
                        <IconLogin width={19} height={19} className={styles.menuItemIcon} />
                    </a>
                </div>
            </div>
            {mobileMenu &&
                <MediaQuery maxWidth='992px'>
                    <div className={styles.mobileMenuContainer}>
                            {/* <Link to="/how-it-works" className={styles.menuTitle}>How It Works</Link> */}
                            <span className={styles.menuTitle}>Industries We Serve</span>
                            <Link to="/automotive" className={styles.menuServe}>
                                Automotive
                            </Link>

                            <Link to="/highered" className={styles.menuServe}>
                                Higher Education
                            </Link>

                            <Link to="/non-profit" className={styles.menuServe}>
                                Non-profit
                            </Link>

                            <Link to="/real-estate" className={styles.menuServe}>
                                Real Estate
                            </Link>

                            <Link to="/contact" className={cn([ styles.menuTitle ])}>
                                Contact Us
                            </Link>
                            <button onClick={() => setModalOpen(dispatch, props.modalType)} className="sh-button-primary">Request a demo</button>

                            <a target="_blank" href="https://app.shiftone.io/" className={cn([styles.menuItem, styles.loginMenu])}>
                                Log-In to your Shift1 App <IconLogin width={'40'} height={'40'} />
                            </a>
                    </div>
                </MediaQuery>
            }
        </header>
    )
}

const ArrowDown = () => (
    <svg className={styles.menuItemIcon} width="7px" height="5px" viewBox="0 0 7 5">
        <polygon fill="#EC232F" transform="translate(3.500000, 2.500000) scale(1, -1) translate(-3.500000, -2.500000) " points="3.5 0 7 5 0 5"></polygon>
    </svg>
)

Header.defaultProps = {
    modalType: 'general',
    logoText: ''
}

export default Header
