import React from 'react'
import PropTypes from 'prop-types'

import { StoreProvider } from '../store'

import Header from 'components/Header/Header'
import Footer from 'components/Footer/Footer'
import FooterCTA from 'components/FooterCTA'
import DefaultLayoutModal from 'components/DefaultLayoutModal/DefaultLayoutModal'
import DefaultLayoutProductSheetModal from 'components/DefaultLayoutProductSheetModal/DefaultLayoutProductSheetModal'
import PersonalizedVideoModal from 'components/PersonalizedVideoModal/PersonalizedVideoModal'
import 'styles/global.scss'

import styles from './DefaultLayout.module.scss'


import 'swiper/swiper.scss'

const Layout = props => {
    return (
        <StoreProvider>
            <div style={props.style}>
                {props.header}
                <div className={styles.headerContainer}>
                    <Header
                        modalType={props.modalType}
                        logoText={props.logoText}
                    />
                </div>
                <main>{props.children}</main>
                {props.withCTA && <FooterCTA modalType={props.modalType} />}
                <Footer logoText={props.logoText} />

                <DefaultLayoutModal />
                <DefaultLayoutProductSheetModal />
                <PersonalizedVideoModal />
            </div>
        </StoreProvider>
    )
}

Layout.defaultProps = {
    withCTA: false,
    header: null,
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    logoText: PropTypes.string,
    withCTA: PropTypes.bool,
}

export const setModalOpen = (dispatch, modalType) => {
    dispatch({
        type: 'SET_MODAL',
        payload: modalType,
    })
}

export default Layout
